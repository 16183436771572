import React from 'react';
import './LogoHeader.css';
import logo from './logo-no-background.png';

const LogoHeader = () => {

  return (
    <img src={logo} alt="Header Logo" className="logo-header" style={{ width: '150px' }} />
  );
};

export default LogoHeader;
