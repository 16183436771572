// import './SOHDescription.css'

const SOHDescription = ({ soh }) => {
    let ratingMessage;
    let ratingClass; // You can add classes to style based on the rating

    if (soh >= 90 && soh <= 100) {
        ratingMessage = "Excellent - The EV’s battery is in excellent condition, performing at or near the WLTP range. The vehicle’s range is optimal, and continued usage will maintain this high level of performance.";
        ratingClass = 'excellent-rating';
    } else if (soh >= 75 && soh <= 89) {
        ratingMessage = "Good - The EV’s battery is in good condition and performs near its expected range. Minor optimisations in driving patterns could help improve range and longevity, but overall, the battery is stable.";
        ratingClass = 'good-rating';
    } else if (soh >= 60 && soh <= 74) {
        ratingMessage = "Fair - The EV’s battery is functional but underperforming. It delivers less than the WLTP range, and the vehicle’s range is lower than expected. Further investigation may be needed to maintain adequate performance.";
        ratingClass = 'fair-rating';
    } else if (soh >= 50 && soh <= 59) {
        ratingMessage = "Poor - The EV’s battery is nearing the end of its useful life. This vehicle’s range will be significantly reduced from the original WLTP range, and future usage could require reconditioning or replacement of the battery for optimal performance.";
        ratingClass = 'poor-rating';
    } else {
        ratingMessage = "Invalid SOH Value";
        ratingClass = 'invalid-rating';
    }

    return (
        <h1 className={`soh-rating ${ratingClass}`} style={{ fontSize: '20px', fontWeight: 'bold', marginTop: '20px' }}>
            {ratingMessage}
        </h1>
    );
};

export default SOHDescription