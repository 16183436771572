import React from 'react';
import { Row, Col } from 'react-bootstrap';
import '../shared/form-containers.css';
import RegistrationForm from './RegistrationForm';

const Register = () => {
  return (
    <Row className="rbh-container">
      <Col md={12}>
        <Row className="text-start align-items-start">
          <Col md={6} className="rbh-form-container">
            <h1>Register here:</h1>
            <h5>All fields are mandatory</h5>
            <br></br>
            <RegistrationForm />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Register;
