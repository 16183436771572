import { joinUrl } from '../util';
import { Button, Badge } from 'react-bootstrap';
import { Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import './Dashboard.css'

// TODO: Move somewhere else
export const evaluationStatuses = {
    NOT_STARTED: 0,
    RUNNING: 1,
    SUCCEEDED: 2,
    FAILED: 3,
    FAILED_VEHICLE_ALREADY_RUNNING: 4,
    CANCELLED: 5
};

export function formatIrishDateTime(started_at) {
    if (!started_at) {
        return '';
    }
    const date = new Date(started_at);
    return date.toLocaleString('en-IE', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
    });
}

const evaluation_statuses_display = {
    [evaluationStatuses.NOT_STARTED]: "NOT STARTED",
    [evaluationStatuses.RUNNING]: "RUNNING",
    [evaluationStatuses.SUCCEEDED]: "SUCCEEDED",
    [evaluationStatuses.FAILED]: "FAILED",
    [evaluationStatuses.FAILED_VEHICLE_ALREADY_RUNNING]: "FAILED - VEHICLE ALREADY RUNNING",
    [evaluationStatuses.CANCELLED]: "CANCELLED"
};

export const evaluationTableColumns = [
    {
        name: "Created time",
        selector: (row) => formatIrishDateTime(row.created_at),
        style: { fontSize: '12px' },
        sortable: true,
        width: '10%',
    },
    {
        name: "Vehicle make",
        selector: (row) => row.vehicle?.make?.value || '',
        style: { fontSize: '12px' },
        sortable: true,
        width: '10%',
    },
    {
        name: "Vehicle model",
        selector: (row) => row.vehicle?.model?.value || '',
        style: { fontSize: '12px' },
        sortable: true,
        width: '10%',
    },
    {
        name: "Vehicle year",
        selector: (row) => row.vehicle?.year?.value || '',
        style: { fontSize: '12px' },
        sortable: true,
        width: '10%',
    },
    {
        name: "Status",
        selector: (row) => (
            <div>
                <Badge bg="primary">{evaluation_statuses_display[row.status]}</Badge>
            </div>
        ),
        style: { fontSize: '12px' },
        wrap: true,
        sortable: true,
        sortFunction: (a, b) => { if (a.status > b.status) return -1; if (a.status < b.status) return 1; return 0; },
        width: '20%',
    },
    {
        name: "Start time",
        selector: (row) => formatIrishDateTime(row.started_at),
        sortable: true,
        width: '12.5%',
    },
    {
        name: "Finish time",
        selector: (row) => formatIrishDateTime(row.finished_at),
        sortable: true,
        width: '12.5%',
    },
    {
        name: "Get URL",
        selector: (row) => (
            (row.status === evaluationStatuses.NOT_STARTED || row.status === evaluationStatuses.RUNNING) ? <div>
                <LinkContainer to="/get-url" state={{ "url": joinUrl(window.location.origin, "/evaluations", row.code) }} className="p-1">
                    <Nav.Link>
                        <Button variant="primary" className="w-100">
                            Test URL
                        </Button>
                    </Nav.Link>
                </LinkContainer>
            </div> : (
                row.status === evaluationStatuses.SUCCEEDED ? <LinkContainer to={joinUrl('/report', row.code)} className="p-1">
                    <Nav.Link>
                        <Button variant="primary" className="w-100">
                            View Report
                        </Button>
                    </Nav.Link>
                </LinkContainer> : null
            )
        ),
        width: '15%',
    },
];
