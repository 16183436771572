import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Button } from 'react-bootstrap';
import './RegisterSuccess.css'; // Import your custom CSS

const RegisterSuccess = () => {
    return (
        <div className="register-success-container">
            <div className="content">
                <h1>Registration Successful</h1>
                <p>
                    A confirmation email has been sent to your email address. Please check your email and click on the verification link to activate your account. After verifying your email, you can log in using the link below.
                </p>
                <LinkContainer to="/login">
                    <Button variant="primary" className="go-to-login-button">
                        Go to Login
                    </Button>
                </LinkContainer>
            </div>
        </div>
    );
};

export default RegisterSuccess;
