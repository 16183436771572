import React from 'react';
import '../shared/form-elements.css';
import { Col, Nav, Row } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { Button } from 'react-bootstrap';

const HomeForm = () => {
  return (
    <>
      <Row>
        <Col md={12}>
          <h1>Maximise your EV’s Value</h1>
          <h1>with a Professional Battery </h1>
          <h1>Health Test</h1>
        </Col>
      </Row>
      <br></br>
      <br></br>
      <Row>
        <Col md={6}>
          <LinkContainer to="/login" className="p-1">
            <Nav.Link>
              <Button variant="primary" className="w-100">
                Log In
              </Button>
            </Nav.Link>
          </LinkContainer>
        </Col>
        <Col md={6}>
          <LinkContainer to="/register" className="p-1">
            <Nav.Link>
              <Button variant="primary" className="w-100">
                Register
              </Button>
            </Nav.Link>
          </LinkContainer>
        </Col>
      </Row>
    </>
  );
};

export default HomeForm;
