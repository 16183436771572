import React, { useState } from 'react';
import { Form, Button, Spinner, Toast } from 'react-bootstrap';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import './RegistrationForm.css';
import '../shared/form-elements.css';
import ApiService from '../apis/ApiService';
import TermsText from './TermsText';

const validationSchema = Yup.object().shape({
    username: Yup.string()
        .min(3, 'Company Name must be at least 3 characters')
        .required('Company Name is required'),
    email: Yup.string()
        .email('Invalid email format')
        .matches(
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            'Invalid email format'
        )
        .required('Email is required'),
    password: Yup.string().required('Password is required'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm Password is required'),
    terms: Yup.bool().oneOf([true], 'Terms must be accepted'),
});


const apiService = new ApiService(null, null, false);

const RegistrationForm = () => {
    const [showTerms, setShowTerms] = useState(false);
    const [showErrorToast, setShowErrorToast] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        try {
            const response = await apiService._post(`register/`, {
                name: values.username,
                email: values.email,
                password: values.password,
            });
            if (response.status === 200) {
                resetForm();
                navigate('/register-success');
            }
        } catch (error) {
            console.error(error);
            setErrorMessage(error.response?.data?.detail || 'An error occurred');
            setShowErrorToast(true);
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <>
            <Formik
                initialValues={{
                    username: '',
                    email: '',
                    password: '',
                    confirmPassword: '',
                    terms: false,
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ handleSubmit, handleChange, values, touched, errors, isSubmitting }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Form.Group className="mb-3 form-group-custom">
                            <Form.Label htmlFor="username" className="form-label-custom">Company Name</Form.Label>
                            <Field
                                id="username"
                                type="text"
                                name="username"
                                value={values.username || ''}
                                onChange={handleChange}
                                className={`form-control ${touched.username && errors.username ? 'is-invalid' : ''}`}
                                autoComplete="username"
                            />
                            <ErrorMessage component="div" name="username" className="invalid-feedback" />
                        </Form.Group>

                        <Form.Group className="mb-3 form-group-custom">
                            <Form.Label htmlFor="email" className="form-label-custom">Email</Form.Label>
                            <Field
                                id="email"
                                type="email"
                                name="email"
                                value={values.email || ''}
                                onChange={handleChange}
                                className={`form-control ${touched.email && errors.email ? 'is-invalid' : ''}`}
                                autoComplete="email"
                            />
                            <ErrorMessage component="div" name="email" className="invalid-feedback" />
                        </Form.Group>

                        <Form.Group className="mb-3 form-group-custom">
                            <Form.Label htmlFor="password" className="form-label-custom">Password</Form.Label>
                            <Field
                                id="password"
                                type="password"
                                name="password"
                                value={values.password || ''}
                                onChange={handleChange}
                                className={`form-control ${touched.password && errors.password ? 'is-invalid' : ''}`}
                                autoComplete="new-password"
                            />
                            <ErrorMessage component="div" name="password" className="invalid-feedback" />
                        </Form.Group>

                        <Form.Group className="mb-3 form-group-custom">
                            <Form.Label htmlFor="confirmPassword" className="form-label-custom">Confirm Password</Form.Label>
                            <Field
                                id="confirmPassword"
                                type="password"
                                name="confirmPassword"
                                value={values.confirmPassword || ''}
                                onChange={handleChange}
                                className={`form-control ${touched.confirmPassword && errors.confirmPassword ? 'is-invalid' : ''}`}
                                autoComplete="new-password"
                            />
                            <ErrorMessage component="div" name="confirmPassword" className="invalid-feedback" />
                        </Form.Group>

                        <Form.Group className="mb-3 rbhc-form-terms">
                            <Field
                                type="checkbox"
                                name="terms"
                                id="terms"
                                className={`${touched.terms && errors.terms ? 'is-invalid' : ''}`}
                            />
                            <label htmlFor="terms" className="form-check-label">
                                <span className='form_terms_text'>
                                    I agree to all the
                                    <Button variant="link" onClick={() => setShowTerms(true)} className='register_terms_btn'>
                                        Terms
                                    </Button>
                                    and
                                    <Button variant="link" onClick={() => window.open('https://www.batterycycle.io/privacy-policy/', '_blank')} className='register_privacy_btn'>
                                        Privacy Policies
                                    </Button>
                                </span>
                            </label>
                            <ErrorMessage component="div" name="terms" className="invalid-feedback" />
                        </Form.Group>

                        <Button variant="primary" type="submit" disabled={isSubmitting}>
                            {isSubmitting ? (
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            ) : (
                                'Register'
                            )}
                        </Button>
                    </Form>
                )}
            </Formik>

            <div className="toast-container-center">
                <Toast show={showTerms} onClose={() => setShowTerms(false)} className="toast-info">
                    <Toast.Header>
                        <strong className="me-auto">Terms and Conditions</strong>
                    </Toast.Header>
                    <Toast.Body>
                        <TermsText />
                    </Toast.Body>
                </Toast>
                <Toast show={showErrorToast} onClose={() => setShowErrorToast(false)} className="toast-error">
                    <Toast.Header>
                        <strong className="me-auto">Error</strong>
                    </Toast.Header>
                    <Toast.Body>
                        {errorMessage}
                    </Toast.Body>
                </Toast>
            </div>
        </>
    );
};

export default RegistrationForm;
