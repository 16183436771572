import React from 'react';
import { Row, Col } from 'react-bootstrap';
import '../shared/form-containers.css'
import HomeForm from './HomeForm';

const Home = () => {
  return (
    <Row className="rbh-container">
      <Col md={12}>
        <Row className="text-start align-items-start">
          <Col md={6} className="rbh-form-container">
            <HomeForm />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Home;
