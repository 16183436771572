import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Button, Tabs, Tab } from 'react-bootstrap';
import '../shared/form-elements.css';
import VehiclesTable from './VehiclesTable';
import EvaluationsTable from './EvaluationsTable';
import ApiService from '../apis/ApiService';
import EvaluationsApi from '../apis/Evaluations';
import './Dashboard.css'
import { joinUrl } from '../util';

const Dashboard = () => {
    const [user, setUser] = useState(null);
    const [error, setError] = useState({
        "user": null,
        "evaluations": null,
        "vehicles": null,
    });
    const [vehicles, setVehicles] = useState([]);
    const [evaluations, setEvaluations] = useState([]);

    const navigate = useNavigate();
    const apiService = useMemo(() => new ApiService(navigate, '/login'), [navigate]);
    const evaluationsApi = useMemo(() => new EvaluationsApi(navigate, '/login'), [navigate]);

    const fetchUser = useCallback(async () => {
        try {
            const response = await apiService._get('get_current_user/');
            setUser(response.data);
        } catch (error) {
            setError({ "user": error.message });
        }
    }, [apiService]);

    const fetchVehicles = useCallback(async () => {
        try {
            const response = await apiService._get('vehicles/');
            setVehicles(response.data);
        } catch (err) {
            setError({ "vehicles": err.message });
        }
    }, [apiService]);

    const fetchEvaluations = useCallback(async () => {
        try {
            const response = await evaluationsApi.getAll();
            setEvaluations(response.data);
        } catch (err) {
            setError({ "evaluations": err.message });
        }
    }, [evaluationsApi]);

    useEffect(() => {
        fetchVehicles();
        fetchEvaluations();
        fetchUser();
    }, [fetchVehicles, fetchEvaluations, fetchUser]);

    const handleAddEvaluation = () => {
        evaluationsApi.create().then(r => {
            fetchEvaluations().then(() => {
                const evaluation_code = r?.data?.evaluation?.code
                if (evaluation_code) {
                    // TODO: Have a look at this. Why not just have the evaluation code passed in via url? Why use "state"?
                    navigate("/get-url", {
                        state: { url: joinUrl(window.location.origin, "/evaluations", evaluation_code) },
                    });
                } else {
                    console.error("An error occurred while adding a new evaluation!")
                }
            })
        });
    };

    if (!user || error.user) {
        return <h1>Loading...</h1>
    }

    return (
        <Row className="rbh-container dashboard-table">
            <Col md={12}>
                <Row className="text-start align-items-start">
                    <Col md={12} className="rbh-form-container">
                        <h1>Battery Health Test Sessions</h1>
                    </Col>
                </Row>
            </Col>
            <Col md={12} className="mt-3">
                <Tabs defaultActiveKey="evaluations" id="user-dashboard-tabs">
                    <Tab eventKey="evaluations" title="Evaluations">
                        <EvaluationsTable
                            evaluations={evaluations}
                            error={error.evaluations}
                            navigate={navigate}
                            handleAddEvaluation={handleAddEvaluation}
                        />
                    </Tab>
                    <Tab eventKey="vehicles" title="Vehicles">
                        <VehiclesTable vehicles={vehicles} error={error.vehicles} />
                    </Tab>
                </Tabs>
            </Col>
        </Row>
    );
};

export default Dashboard;
