import ApiService from './ApiService';

class EvaluationsApi extends ApiService {
    constructor(navigate, fallbackRoute, authProtected = true) {
        super(navigate, fallbackRoute, authProtected);
    }

    create() {
        return this._post('/evaluations');
    }

    getAll() {
        return this._get('/evaluations');
    }

    get(code) {
        return this._get(`/evaluations/${code}`)
    }

    start(code, initial_battery_percentage, updates_email = "") {
        return this._post('/evaluations/start', { code, initial_battery_percentage, updates_email });
    }

    cancel(code) {
        return this._post('/evaluations/cancel', { code });
    }

    finish(code, battery_capacity, charging_kwh, user_end_time) {
        return this._post('/evaluations/finish', { code, battery_capacity, charging_kwh, user_end_time });
    }

    progress(code) {
        return this._get(`/evaluations/${code}/progress`)
    }

    getForReport(code) {
        return this._get(`/evaluations/${code}/report`)
    }

    getOwner(code) {
        return this._get(`/evaluations/${code}/owner`, { code })
    }
}

export default EvaluationsApi;
