import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import UsersApi from '../apis/Users';
import { Button } from 'react-bootstrap';
import './EmailVerification.css';
import Loading from '../shared/Loading';

const usersApi = new UsersApi(null, null, false);

const EmailVerification = () => {
    const { verificationGuid } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true); // For handling loading state
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');

    useEffect(() => {
        const checkAndActivateUser = async () => {
            try {
                const isActive = await usersApi.is_active(verificationGuid);
                console.log("isActive", isActive.data);

                if (isActive.data) {
                    setTitle('Already Verified!');
                    setMessage('This user has already been verified!');
                } else {
                    const activationSuccess = await usersApi.activate(verificationGuid);
                    if (activationSuccess) {
                        setTitle('Verification Succeeded!');
                        setMessage('User activated successfully! You can log in to the app now!');
                    } else {
                        setTitle('Activation Failed');
                        setMessage('We were unable to activate this user. Please try again.');
                    }
                }
            } catch (error) {
                setTitle('Error');
                setMessage('Something went wrong, please try again later.');
            } finally {
                setLoading(false); // Set loading to false when the request completes
            }
        };

        if (verificationGuid) {
            checkAndActivateUser();
        }
    }, [verificationGuid]);

    return (
        <div>
            <div className="verification_body">
                {loading ? (
                    <Loading /> // Show loading spinner while awaiting API response
                ) : (
                    <>
                        <h2>{title}</h2>
                        <p>{message}</p>
                        <div className="line"></div>
                        <Button variant="primary" className="w-100 go-to-login-button" onClick={() => navigate('/login')}>
                            Go to Login
                        </Button>
                    </>
                )}
            </div>
        </div>
    );
};

export default EmailVerification;
