import React from 'react';
import { useParams } from 'react-router-dom';
import { evaluationStatuses } from '../dashboard/EvaluationsTableColumns';
import PreliminaryDetailsEntryPage from './PreliminaryDetailsEntryPage';
import ChargingDetailsPage from './ChargingDetailsPage';
import ThankYouPage from './ThankYouPage';
import EvaluationProgressPage from './EvaluationProgressPage';
import EvaluationError from './EvaluationError';
import { useEvaluationQuery } from './useEvaluationQuery';
import { useEvaluation } from './useEvaluation';
import Loading from '../shared/Loading';
import { PROGRESS_EMAIL_SENT } from './config';

const renderComponentForStatus = {
    [evaluationStatuses.NOT_STARTED]: (evaluation, setEvaluation) => (
        <PreliminaryDetailsEntryPage evaluation={evaluation} setEvaluation={setEvaluation} />
    ),
    [evaluationStatuses.RUNNING]: (evaluation, setEvaluation) => {
        if (evaluation.progress_email_status === PROGRESS_EMAIL_SENT) {
            return <ChargingDetailsPage evaluation={evaluation} setEvaluation={setEvaluation} />;
        } else if (evaluation.started_at && evaluation.initial_battery_percentage) {
            return <EvaluationProgressPage evaluation={evaluation} setEvaluation={setEvaluation} />;
        } else {
            return <PreliminaryDetailsEntryPage evaluation={evaluation} setEvaluation={setEvaluation} />;
        }
    },
    [evaluationStatuses.SUCCEEDED]: (evaluation) => {
        return evaluation.charging_kwh && evaluation.finished_at ? (
            <ThankYouPage evaluation={evaluation} />
        ) : (
            <EvaluationError
                evaluationCode={evaluation.code}
                error="Essentials details not acquired"
                errorDescription="Failed to acquire the essentials details to complete the evaluation."
            />
        );
    },
    [evaluationStatuses.CANCELLED]: (evaluation) => (
        <EvaluationError
            evaluationCode={evaluation.code}
            error="Evaluation cancelled"
            errorDescription="The evaluation was cancelled by the user or system."
        />
    ),
    [evaluationStatuses.FAILED]: (evaluation) => (
        <EvaluationError
            evaluationCode={evaluation.code}
            error="Evaluation failed"
            errorDescription="An unexpected error occurred during the evaluation."
        />
    ),
    [evaluationStatuses.FAILED_VEHICLE_ALREADY_RUNNING]: (evaluation) => (
        <EvaluationError
            evaluationCode={evaluation.code}
            error="Evaluation failed"
            errorDescription="Another evaluation is currently running for the same vehicle."
        />
    )
};

const Evaluation = () => {
    const { evaluationCode } = useParams();
    const { error, errorDescription } = useEvaluationQuery();

    // Fetch evaluation data
    const { evaluation, setEvaluation, isLoading } = useEvaluation(
        evaluationCode,
        error,
        errorDescription
    );

    if (error && errorDescription) {
        return <EvaluationError evaluationCode={evaluationCode} error={error} errorDescription={errorDescription} />;
    } else if (isLoading) {
        return <Loading />;
    } else if (!evaluation) {
        return (
            <EvaluationError
                evaluationCode={evaluationCode}
                error="Unknown evaluation"
                errorDescription="Unable to determine the evaluation state."
            />
        );
    }

    const ComponentToRender = renderComponentForStatus[evaluation.status];
    if (ComponentToRender) {
        return ComponentToRender(evaluation, setEvaluation);
    } else {
        return (
            <EvaluationError
                evaluationCode={evaluation.code}
                error="Unknown evaluation state"
                errorDescription="The current evaluation state is not recognized."
            />
        );
    }
};

export default Evaluation;
