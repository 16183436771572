import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import '../shared/form-containers.css'
import { Nav, Button, Alert } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useLocation } from 'react-router-dom';

const GetUrl = (props, props1) => {
    const location = useLocation();
    const { url } = location.state || {}; // Extract the passed state
    const [showLinkCopied, setShowLinkCopied] = useState(false);
    const handleCopyUrl = (url) => {
        navigator.clipboard.writeText(url);
        setShowLinkCopied(true);
        setTimeout(() => setShowLinkCopied(false), 2000);
    };
    return (
        <div className="rbh-container rbh-get-url">
            <Row className="text-start align-items-start">
                <Col md={9}>
                    <div>
                        <h3>
                            <p>
                                Here is the link below to start the test. You can copy the link to send it to a tester or click on it to start the test.
                            </p>
                            <p style={{ "overflowWrap": 'anywhere' }}>
                                <a href={url}>Click to open test</a>
                            </p>
                        </h3>
                    </div>
                </Col>
            </Row >
            {showLinkCopied && <Alert size="sm" style={{ padding: '5px 10px', fontSize: '14px', lineHeight: '1.2em' }} variant="warning">{"Link copied to clipboard!"}</Alert>}
            <Row className="text-start align-items-start">
                <Col md={9}>
                    <Row className='rbh-geturl-btn-container'>
                        <Col md={6} className='rbh-back-dashboard-btn'>
                            <LinkContainer to="/dashboard" className="p-1">
                                <Nav.Link className='rbh-back-dashboard-link'>
                                    <Button variant="primary" className="w-100">
                                        Back to dashboard
                                    </Button>
                                </Nav.Link>
                            </LinkContainer>
                        </Col>
                        <Col md={6}>
                            <Button variant="primary" className="w-100" onClick={() => handleCopyUrl(url)}>
                                Copy link to clipboard
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div >
    );
};

export default GetUrl;
