import React, { useState } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import '../shared/form-containers.css';
import EvaluationsApi from '../apis/Evaluations';

const ChargingDetailsPage = ({ evaluation, setEvaluation }) => {
    const [batteryCapacity, setBatteryCapacity] = useState('');
    const [kWhUsed, setKWhUsed] = useState('');
    const [userEndTime, setUserEndTime] = useState('');
    const evaluationsApi = new EvaluationsApi(null, null, false);

    const handleSubmit = () => {
        evaluationsApi.finish(evaluation.code, batteryCapacity, kWhUsed, userEndTime).then(r => {
            setEvaluation(r.data);
        });
    };

    const handleBatteryCapacityChange = e => {
        const value = e.target.value;
        if (value === '' || Number(value) >= 0) {
            setBatteryCapacity(value);
        }
    };

    const handleKWhUsedChange = e => {
        const value = e.target.value;
        if (value === '' || Number(value) >= 0) {
            setKWhUsed(value);
        }
    };

    const handleUserEndTimeChange = e => {
        setUserEndTime(e.target.value);
    };

    return (
        <div className="rbh-container">
            <Row className="text-start align-items-start">
                <Col md={9}>
                    <div>
                        <h1>Battery Test Completion</h1>
                        <h4>We've captured enough data to complete the test, we will stop collecting data when you stop charging and complete the test.</h4>
                        <h4>To complete the test, stop charging and fill in the details on the charging session below.</h4>
                    </div>
                    <Form>
                        <Form.Group controlId="batteryCapacity">
                            <Form.Label>Battery Capacity (kWh)</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="e.g., 60 kWh"
                                value={batteryCapacity}
                                onChange={handleBatteryCapacityChange}
                                min="0"
                            />
                        </Form.Group>
                        <Form.Group controlId="kWhUsed" className="mt-3">
                            <Form.Label>kWh used in charging session</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="e.g., 15 kWh"
                                value={kWhUsed}
                                onChange={handleKWhUsedChange}
                                min="0"
                            />
                        </Form.Group>
                        <Form.Group controlId="userEndTime" className="mt-3">
                            <Form.Label>End time of the charging session (HH:mm)</Form.Label>
                            <Form.Control
                                type="time"
                                value={userEndTime}
                                onChange={handleUserEndTimeChange}
                            />
                        </Form.Group>
                        <Row className="mt-4">
                            <Col className="text-end">
                                <Button variant="primary" onClick={handleSubmit}>
                                    Submit
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </div>
    );
};

export default ChargingDetailsPage;
